import { Spacer } from "~/widgets/spacer";
import { ProfileCardsList } from "./profile_cards_list";
import { ProfileImage } from "./profile_image";
import { SupportIcon } from "~/assets/svg_icons/support_icon";
import { DividerV2 } from "~/widgets/divider";
import { VoucherDetails } from "~/server/types/order";
import { BrandCollectionHeader } from "../brand-collection-landing/brand_collection_header";
import { openIntercom } from "~/utils/third_party/intercom";

export type BrandCollectionProfilePageProps = {
  giftingKey: string;
  coinsCount: number;
  phoneNumber: string;
  cardList: VoucherDetails[];
  themeImageUrl: string;
  logoUrl?: string;
};

export function BrandCollectionProfilePageComponent(
  props: BrandCollectionProfilePageProps
) {
  return (
    <div class="noScrollbar relative flex h-full w-full flex-col items-center overflow-y-scroll bg-black ">
      <img
        src={props.themeImageUrl}
        alt=""
        class="pointer-events-none absolute top-[40px] z-[0]"
      />
      <div class="  relative z-10 flex min-h-screen w-full flex-col items-center   text-white">
        <BrandCollectionHeader
          showBalance={() => true}
          showProfile={() => true}
          isAbsolute={false}
          showHeader={() => true}
          coinsAvailable={() => props.coinsCount}
          giftingKey={props.giftingKey}
          logoUrl={props.logoUrl}
        />
        <div class="noScrollbar flex w-full max-w-[450px] flex-col items-start justify-start overflow-x-hidden px-4 ">
          <Spacer height={24} />
          <UserDetails />
          <Spacer height={17} />
          <MyGiftCardDivider />
          <Spacer height={24} />
          <ProfileCardsList cardList={props.cardList} />
        </div>
      </div>
    </div>
  );

  function UserDetails() {
    return (
      <>
        <div class="flex w-full flex-row items-start justify-between ">
          <div class="rounded-full border border-basePrimaryDark bg-black">
            <ProfileImage />
          </div>
          <div
            onClick={() => {
              openIntercom();
            }}
            class="flex flex-row justify-center gap-1 rounded-full border border-basePrimaryDark bg-[#020202] px-2 py-[7px]"
          >
            <SupportIcon />
            <span class="text-medium text-textNormal">Support</span>
          </div>
        </div>
        <Spacer height={12} />
        <span class="text-h3 text-white">{props.phoneNumber}</span>
      </>
    );
  }

  function MyGiftCardDivider() {
    return (
      <div class="flex w-full flex-row items-center justify-center gap-2">
        <span class="grow text-nowrap text-medium text-white">
          My gift cards
        </span>
        <DividerV2 class=" bg-basePrimaryDark" />
      </div>
    );
  }
}
